import React from 'react';
import { initializeApp } from 'firebase/app';
import {
	Timestamp,
	addDoc,
	collection,
	deleteDoc,
	doc,
	getFirestore,
	onSnapshot,
	orderBy,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import { getAuth, signInWithCustomToken } from 'firebase/auth';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

/**
 * @typedef Message
 * @property {string} id
 * @property {string} text
 * @property {string} senderId
 * @property {string} receiverId
 * @property {Timestamp} timestamp
 * @property {Boolean} isNew
 */

/**
 * @typedef Converstion
 * @property {string} id
 * @property {string[]} participants 
 * @property {Message[]} messages
 */

/**
 * @template S
 * @param {string} collectionName The collection in firestore you want to access
 * @param {string | null} default_id The id of the document in the collection
 * @returns {[S, Dispatch<SetStateAction<S>>, () => Promise<void>, boolean]} [data, updateDoc, removeDoc, isLoading]
 * 
 * Returns a firebase document value, a function to update the document, a function to remove the document, and a boolean indicating whether the document is loading.
 */
const useDocument = (collectionName, default_id) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [docu, setStateDoc] = React.useState();
	const [id, setID] = React.useState(default_id);

	const docRef = React.useMemo(
		() => (id ? doc(db, collectionName, id) : null),
		[collectionName, id]
	);
	const updateMemoDoc = React.useMemo(
		() => (nextStateValue) => {
			setIsLoading(true);
			if (docRef) {
				setDoc(docRef, nextStateValue, { merge: true }).then(() => {
					setIsLoading(false);
				});
			} else {
				addDoc(collection(db, collectionName), nextStateValue).then((newDocRef) => {
					setID(newDocRef.id);
					setIsLoading(false);
				});
			}
		},
		[docRef, collectionName]
	);
	const removeMemoDoc = React.useMemo(
		() => () => {
			setIsLoading(true);
			deleteDoc(docRef).then(() => {
				setID(null)
				setIsLoading(false);
			});
		},
		[docRef]
	);

	React.useEffect(() => {
		setID(default_id);
	}, [default_id]);

	React.useEffect(() => {
		if (docRef) {
			setIsLoading(true);
			return onSnapshot(docRef, (nextDocumentSnapshot) => {
				if (nextDocumentSnapshot.exists) {
					setStateDoc({ ...nextDocumentSnapshot.data(), id: nextDocumentSnapshot.id, path: nextDocumentSnapshot.ref.path });
				} else {
					setStateDoc(null);
				}
				setIsLoading(false);
			});
		} else {
			setStateDoc(null);
		}
	}, [docRef]);
	return [docu, updateMemoDoc, removeMemoDoc, isLoading];
};

/**
 * @template T
 * @param {string} collectionPath
 * @param {[string, string, any][]?} wheres
 * an array of arrays, e.g., [['state', '==', 'CO'], ['name', '==', 'Denver']]
 * @param {[string, string][]?} orderBys
 * an array of arrays, e.g., [['state', 'asc'], ['name', 'desc']]
 * @returns {[T[], boolean]}
 */
const useQuery = (collectionPath, { wheres = [], orderBys = [] } = {}) => {
	const [isLoading, setIsLoading] = React.useState(true);
	const [docs, setDocs] = React.useState([]);
	const collectionRef = React.useMemo(
		() => (collectionPath ? collection(db, collectionPath) : null),
		[collectionPath]
	);

	React.useEffect(() => {
		if (collectionRef) {
			setIsLoading(true);
			const whereParameters = wheres.map((w) => where(...w));
			const orderByParameters = orderBys.map((o) => orderBy(...o));
			const q = query(collectionRef, ...whereParameters, ...orderByParameters);
			return onSnapshot(q, (querySnapshot) => {
				const docs = querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
					path: doc.ref.path,
				}));
				setDocs(docs);
				setIsLoading(false);
			});
		}
	}, [collectionRef, JSON.stringify(wheres), JSON.stringify(orderBys)]);
	return [docs, isLoading];
};

const loginWithToken = async (token) => {
	if (!token) return null;
	const credential = await signInWithCustomToken(auth, token);
	return credential.user;
}

/**
 * 
 * @param {string} conversationId
 * @returns {[Converstion, Dispatch<SetStateAction<Converstion>>, () => Promise<void>, boolean]} [data, updateDoc, removeDoc, isLoading] 
 */
export const useConversation = (conversationId, token) => {
	loginWithToken(token);
	return useDocument('conversations', conversationId);
}

/**
 * 
 * @param {string} userId 
 * @returns {[Converstion[], boolean]} [data, isLoading]
 */
export const useConversations = (userId1, userId2 = null, token) => {
	const [conversations, isLoading] = useQuery('conversations', {
		wheres: [['participants', 'array-contains', userId1]]
	});

	const foundConversations = React.useMemo(() => {
		loginWithToken(token);
		return conversations.filter(conversation => {
			if (userId2)
				return conversation.participants.includes(userId2);
			return true;
		});
	}, [conversations, userId2, token]);
	return [foundConversations, isLoading];
}
