import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  IconAmenities,
} from '../../components';

import { Link } from 'react-router-dom';

import css from './SignupBusinessForm.module.css';

const SignupBusinessFormComponent = props => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showError, setshowError] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <>
      <FinalForm
        {...props}
        validateOnBlur={false}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            formId,
            handleSubmit,
            inProgress,
            invalid,
            intl,
          } = fieldRenderProps;

          const emailRequiredMessage = intl.formatMessage({
            id: 'SignupForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          // password
          const passwordLabel = intl.formatMessage({
            id: 'SignupForm.passwordLabel',
          });
          const passwordRequiredMessage = intl.formatMessage({
            id: 'SignupForm.passwordRequired',
          });
          const passwordMinLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooShort',
            },
            {
              minLength: validators.PASSWORD_MIN_LENGTH,
            }
          );
          const passwordMaxLengthMessage = intl.formatMessage(
            {
              id: 'SignupForm.passwordTooLong',
            },
            {
              maxLength: validators.PASSWORD_MAX_LENGTH,
            }
          );
          const passwordMinLength = validators.minLength(
            passwordMinLengthMessage,
            validators.PASSWORD_MIN_LENGTH
          );
          const passwordMaxLength = validators.maxLength(
            passwordMaxLengthMessage,
            validators.PASSWORD_MAX_LENGTH
          );
          const passwordRequired = validators.requiredStringNoTrim(
            passwordRequiredMessage
          );
          const passwordValidators = validators.composeValidators(
            passwordRequired,
            passwordMinLength,
            passwordMaxLength
          );

          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });

          const nameInvalidMessage = intl.formatMessage({
            id: 'SignupForm.nameInvalid',
          });
          const firstNameRequired = validators.required(
            firstNameRequiredMessage
          );
          const firstNameValid = validators.nameFormatValid(nameInvalidMessage);

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = inProgress;
          const submitDisabled = invalid || submitInProgress;

          return (
            <Form className={classes} onSubmit={handleSubmit}>
              <div className={css.switchBusinessButtons}>
                <Link
                  to="/signup"
                  className={`${css.switchButton}`}
                >
                  Personal
                </Link>
                <Link
                  to="/signup/business"
                  className={`${css.swithButtonClicked} ${css.switchButton}`}
                >
                  Business
                </Link>
              </div>

              <div>
                <div className={css.name}>
                  <FieldTextInput
                    className={css.NameRoot}
                    type="text"
                    id={formId ? `${formId}.fname` : 'fname'}
                    name="businessName"
                    autoComplete="given-name"
                    label="Business Name"
                    // placeholder={firstNamePlaceholder}
                    validate={validators.composeValidators(
                      firstNameRequired,
                      firstNameValid
                    )}
                    formatOnBlur={true}
                    format={value => value?.trim()}
                  />
                </div>

                <FieldTextInput
                  type="email"
                  id={formId ? `${formId}.email` : 'email'}
                  name="email"
                  autoComplete="email"
                  className={css.NameRoot}
                  label="Business Email"
                  validate={validators.composeValidators(
                    emailRequired,
                    emailValid
                  )}
                />
              </div>

              <div className={css.passwordContainer}>
                <FieldTextInput
                  className={css.password}
                  type={passwordShown ? 'text' : 'password'}
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={passwordLabel}
                  validate={passwordValidators}
                />
                {passwordShown ? (
                  <span
                    className={css.passwordShowHide}
                    onClick={togglePassword}
                  >
                    <IconAmenities type="openEye" />
                  </span>
                ) : (
                  <span
                    className={css.passwordShowHide}
                    onClick={togglePassword}
                  >
                    <IconAmenities type="closeEye" />
                  </span>
                )}
              </div>

              <div className={css.bottomWrapper}>
                <p className={css.bottomWrapperText}>
                  By signing up you agree to the Runtize terms of service and privacy policy.
                </p>
                <PrimaryButton
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled || showError}
                >
                  <FormattedMessage id="SignupForm.signUp" />
                </PrimaryButton>
                <h6>
                  <FormattedMessage id="SignupForm.or" />
                </h6>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

SignupBusinessFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupBusinessFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupBusinessForm = compose(injectIntl)(SignupBusinessFormComponent);
SignupBusinessForm.displayName = 'SignupBusinessForm';

export default SignupBusinessForm;
