import React from 'react';
import { Button, IconClose, IconCollection, LocationSearchBar, Modal } from '../../components';
import css from './FieldSelectDeliveryInput.module.css';

export const calculateDeliveryPrice = (address) => {
    if (!address || ['new york', 'new york city', 'nyc', 'ny'].some((el) => address.toLowerCase().includes(el))) {
      return 0;
    }
    return 14;
  }

const FieldSelectDeliveryInput = ({ open, onClose, listingLocation, deliveryPrice, handleDeliveryOption, delivery, deliveryAddress }) => {
    const [openModal, setOpenModal] = React.useState(false);
    const [modelData, setModelData] = React.useState(null);
    const [location, setLocation] = React.useState(deliveryAddress);
    const [deliveryChecked, setDeliveryChecked] = React.useState(delivery);
    const handleModal = (data) => {
        setModelData(data);
        setOpenModal(true);
    }
    const formatLocation = listingLocation?.address && listingLocation.address.split(',');
    const [previewDeliveryPrice , setPreviewDeliveryPrice] = React.useState(deliveryPrice);

    const onSave = () => {
        const delivery = document.querySelector('input[name="delivery"]:checked')?.value;
        const leaveAtDoor = document.querySelector('input[name="leaveAtDoor"]:checked')?.value;
        const aus = document.querySelector('input[name="aus"]')?.value;
        handleDeliveryOption({
            delivery: delivery === 'ship',
            leaveAtDoor,
            location: delivery === 'ship' ? location : null,
            aus
        });
        onClose();
    }

    const closeBtn = (
        <Button onClick={onClose}>
            <IconClose rootClassName={css.closeIcon} />
        </Button>
    )

    return (
        <div>
            <div style={{
                display: open ? 'block' : 'none',
                position: 'fixed',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.76)',
                zIndex: '999'
            }} onClick={onClose}>
                <div className={css.deliveryPopup}
                    onClick={(e) => { e.stopPropagation() }} style={{paddingTop:"34px",paddingLeft:"24px",paddingRight:"24px"}}>

                    <h4>
                        <b>Item Delivery</b>
                        {closeBtn}
                    </h4>
                    <div className={css.method}>
                        <label>Pick it up
                            <div className={css.brandStoreIcon}
                                onClick={() => { handleModal('pickup') }}>
                                <IconCollection name="BRAND_STORE_INFO_FILL" />
                            </div>
                        </label>
                        <div className={css.inputWrapper}>
                            <input type="radio" name="delivery" value="pickup" defaultChecked={!delivery}
                                onChange={() => { setDeliveryChecked(false) }}
                            />
                            <p><span>{formatLocation ? formatLocation[1] : null}, {formatLocation ? formatLocation[2] : null}</span> </p>
                            <span className={css.primaryColor}>Free</span>
                        </div>
                    </div>

                    <div className={css.divider}>
                        <h4><span>OR</span></h4>
                    </div>
                    <div className={css.method}>
                        <label>Delivered to you
                            <div className={css.brandStoreIcon} onClick={() => { handleModal('delivered') }} style={{zIndex:1}}>
                                <IconCollection name="BRAND_STORE_INFO_FILL" />
                            </div>
                        </label>
                        <div className={css.inputWrapper} style={{padding:"3px 15px"}}>
                            <input type="radio" name="delivery" value="ship" defaultChecked={delivery}
                                onChange={() => { setDeliveryChecked(true) }}
                            />
                            {/* <input type="text" placeholder="Enter an address" style={{ flex: 1 }} /> */}
                            <div className={css.addressInput}>
                                <LocationSearchBar
                                    onSubmit={(e) => {
                                        setLocation(e?.location?.selectedPlace);
                                        setPreviewDeliveryPrice(calculateDeliveryPrice(e?.location?.selectedPlace?.address));
                                    }}
                                    searchData={location?.address}
                                    //   currentUser={currentUser}
                                    initialValues={{
                                        location: location,
                                    }}
                                    hideButtons={true}
                                    onChange={(e) => {
                                        setLocation(e);
                                        setPreviewDeliveryPrice(calculateDeliveryPrice(e?.selectedPlace?.address));
                                    }}
                                    disabled={!deliveryChecked}
                                />
                            </div>

                            <span className={css.primaryColor}>${!!location?.selectedPlace?.address  && location.selectedPlace.address.split(',').length > 3 ? previewDeliveryPrice : 0}</span>
                        </div>
                    </div>

                    <div className={css.method} style={{margin:"30px 0 0 0"}}>
                        <label>Appartment/Unit/Suite
                            <div className={css.brandStoreIcon}
                                onClick={() => { handleModal('aus') }}>
                                <IconCollection name="BRAND_STORE_INFO_FILL" />
                            </div>
                        </label>
                        <div className={css.inputWrapper} style={{padding:"14px"}}>
                            <input type="text" placeholder="Optional" disabled={!deliveryChecked} name="aus" />
                        </div>
                    </div>

                    <button className={css.saveButton} style={{borderRadius:"4px",height:"54px"}} onClick={onSave} disabled={deliveryChecked && !location?.selectedPlace}>SAVE</button>

                </div>
            </div>
            <Modal
                isOpen={openModal}
                onManageDisableScrolling={() => { }}
                listingStep2={true}
                listingStep={false}
                themeModal={true}
                onClose={() => setOpenModal(false)}>
                <div className={css.modalContainer} style={{alignItems:"start"}}>
                    <div className={css.infoModal}>
                        <div className={css.infoIcon}>
                            <IconCollection name="WRITE_ABOUT_INFO" />    
                        </div>
                    <div style={{width: "100%"}}>
                        <div className={css.modalTitle}>
                            <h2>
                                {modelData == 'pickup' && <b>Pick it up</b>}
                                {modelData == 'delivered' && <b>Delivered to you</b>}
                                {modelData == 'aus' && <b>Appartment/Unit/Suite</b>}
                                {modelData == 'atdoor' && <b>Leave item at door?</b>}
                            </h2>
                        </div>
                        <div className={css.paragraphList}>
                            <p>
                                {modelData == 'pickup' && "Pick up your rented item from the lender's location at rental start and take it back at rental end"}
                                {modelData == 'delivered' && "Get your rented item door delivered at rental start and picked back up at rental end"}
                                {modelData == 'aus' && "Enter to enable door delivery"}
                                {modelData == 'atdoor' && "Our courier will leave the item in front of your door, so you don't have to be around when he delivers it. You'll get a text notificaiton when the courier has tour item and once it's dropped off"}
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default FieldSelectDeliveryInput;
