import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import CategoryPage from './containers/CategoryPage/CategoryPage';
import MessagePage from './containers/MessagePage/MessagePage';
import MessageListPage from './containers/MessageListPage/MessageListPage';
import EarnPage from './containers/EarnPage';
import BusinessProfileSelf from './containers/BusinessProfileSelf/BusinessProfileSelf';
import BusinessProfileOthers from './containers/BusinessProfileOthers/BusinessProfileOthers';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ './containers/ManageListingsPage/ManageListingsPage'
  )
);
const DashboardListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "AboutPage" */ './containers/DashboardListingsPage/DashboardListingsPage'
  )
);

const SavedListingPage = loadable(() =>
  import(
    /* webpackChunkName: "SavedListingPage" */ './containers/SavedListingPage/SavedListingPage'
  )
);
const SearchPage = loadable(() =>
  import(
    /* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */ './containers/SearchPage/SearchPage'
  )
);
const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TransactionPage = loadable(() =>
  import(
    /* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage'
  )
);
const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage')
);

const ListingDonePage = loadable(() =>
  import(
    /* webpackChunkName: "ListingDonePage" */ './containers/ListingDonePage/ListingDonePage'
  )
);
// ListingDonePage
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'
  )
);
const CheckoutPage = loadable(() =>
  import(
    /* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage'
  )
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const EditListingPage = loadable(() =>
  import(
    /* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage'
  )
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage')
);
const RentalPage = loadable(() =>
  import(
    /* webpackChunkName: "InboxPage" */ './containers/RentalPage/RentalPage'
  )
);
const LandingPage = loadable(() =>
  import(
    /* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage'
  )
);
const LandingPageV2 = loadable(() =>
  import(
    /* webpackChunkName: "LandingPageV2" */ './containers/LandingPageV2/LandingPageV2'
  )
);
const SupportPage = loadable(() =>
  import(
    /* webpackChunkName: "SupportPage" */ './containers/SupportPage/SupportPage'
  )
);
const ListingPage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'
  )
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'
  )
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(
    /* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage'
  )
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);

const DashboardPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ './containers/DashboardPage/DashboardPage'
  )
);
const AddressPage = loadable(() =>
  import(
    /* webpackChunkName: "AddressPage" */ './containers/AddressPage/AddressPage'
  )
);
AddressPage;
const ContactUsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage/ContactUsPage'
  )
);
const IdentityPage = loadable(() =>
  import(
    /* webpackChunkName: "IdentityPage" */ './containers/IdentityPage/IdentityPage'
  )
);
const CreateYourProfilePage = loadable(() =>
  import(
    /* webpackChunkName: "CreateYourProfilePage" */ './containers/CreateYourProfilePage/CreateYourProfilePage'
  )
);
// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(
    /* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage'
  )
);

const SettingPage = loadable(() =>
  import(
    /* webpackChunkName: "StyleguidePage" */ './containers/SettingPage/SettingPage'
  )
);
const BookingRequestPage = loadable(() =>
  import(
    /* webpackChunkName: "StyleguidePage" */ './containers/BookingRequestPage/BookingRequestPage'
  )
);
const ReservationsPage = loadable(() =>
  import(
    /* webpackChunkName: "StyleguidePage" */ './containers/ReservationsPage/ReservationsPage'
  )
);
const BusinessProfileCreate = loadable(() =>
  import(
    /* webpackChunkName: "BusinessProfileCreate" */ './containers/BusinessProfileCreate/BusinessProfileCreate'
  )
);

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
export const draftId = '00000000-0000-0000-0000-000000000000';
export const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

const getSearchParamsType = params => {
  if (typeof params === 'string') {
    return params;
  }
  if (!params || Object.keys(params).length === 0 || params === true) {
    return '';
  }
  return `?${Object.keys(params)
    .map(key => `${key}=${params[key]}`)
    .join('&')}`;
};

const routeConfiguration = () => {
  /**
   * @type {import("react-router-dom").Rou}
   */
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/v2',
      name: 'LandingPageV2',
      component: LandingPageV2,
      loadData: pageDataLoadingAPI.LandingPage.loadData,
    },
    {
      path: '/support-page',
      name: 'SupportPage',
      component: SupportPage,
      loadData: pageDataLoadingAPI.SupportPage.loadData,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/listings',
      name: 'ManageListingPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
    },
    {
      path: '/categoryPage',
      name: 'CategoryPage',
      component: CategoryPage,
      // loadData: pageDataLoadingAPI.ManageListingsPage.loadData
    },
    {
      path: '/dashboard/listings',
      name: 'DashboardListingsPage',
      component: DashboardListingsPage,
      loadData: pageDataLoadingAPI.DashboardListingsPage.loadData,
    },
    {
      path: '/dashboard/reservations',
      name: 'ReservationsPage',
      component: ReservationsPage,
      loadData: pageDataLoadingAPI.ReservationsPage.loadData,
    },
    {
      path: '/listing-done-page',
      name: 'ListingDonePage',
      component: ListingDonePage,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/category/:category',
      name: 'SearchPage',
      component: SearchPage,
      loadData: (p, s) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          p,
          [getSearchParamsType(s), `pub_category=${p.category}`].join('&')
        );
      },
    },
    {
      path: '/rent-apple-vision-pro/brooklyn-ny',
      name: 'SearchPageRentAppleVisionPro',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'apple vision',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [
            getSearchParamsType(search),
            'pub_subCategory=vrHeadsets',
            'keywords=apple vision pro',
          ].join('&')
        );
      },
    },
    {
      path: '/pressure-washer-rental/brooklyn-ny',
      name: 'SearchPagePressureWasher',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'pressure washer',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=pressureWashers'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/tool-rental/brooklyn-ny',
      name: 'SearchPageTool',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'tool',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=tool'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/moped-rental/brooklyn-ny',
      name: 'SearchPageMoped',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'moped',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=moped'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/bike-rental/brooklyn-ny',
      name: 'SearchPageBike',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'bike',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=bike'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/carpet-cleaner-rental/brooklyn-ny',
      name: 'SearchPageCarpetCleaner',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'carpet cleaner',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=carpetCleaner'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/ladder-rental/brooklyn-ny',
      name: 'SearchPageLadder',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'ladder',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=ladder'].join('&')
        );
      },
    },
    {
      path: '/generator-rental/brooklyn-ny',
      name: 'SearchPageGenerator',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'generator',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=generator'].join('&')
        );
      },
    },
    {
      path: '/pallet-jack-rental/brooklyn-ny',
      name: 'SearchPagePalletJack',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'pallet jack',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=palletJack'].join('&')
        );
      },
    },
    {
      path: '/paint-sprayer-rental/brooklyn-ny',
      name: 'SearchPagePaintSprayer',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'paint sprayer',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=paintSprayer'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/jack-hammer-rental/brooklyn-ny',
      name: 'SearchPageJackHammer',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'jack hammer',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=jackHammer'].join('&')
        );
      },
    },
    {
      path: '/air-compressor-rental/brooklyn-ny',
      name: 'SearchPageAirCompressor',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'air compressor',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=airCompressor'].join(
            '&'
          )
        );
      },
    },
    {
      path: '/hand-truck-rental/brooklyn-ny',
      name: 'SearchPageHandTruck',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'hand truck',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=handTruck'].join('&')
        );
      },
    },
    {
      path: '/welder-rental/brooklyn-ny',
      name: 'SearchPageWelder',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'welder',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=welder'].join('&')
        );
      },
    },
    {
      path: '/nail-gun-rental/brooklyn-ny',
      name: 'SearchPageNailGun',
      component: SearchPage,
      extraProps: {
        urlQueryParams: 'nail gun',
      },
      loadData: (params, search) => {
        return pageDataLoadingAPI.SearchPage.loadData(
          params,
          [getSearchParamsType(search), 'pub_subCategory=nailGun'].join('&')
        );
      },
    },
    {
      path: '/savedListings',
      name: 'SavedListingPage',
      component: SavedListingPage,
      loadData: pageDataLoadingAPI.SavedListingPage.loadData,
    },
    {
      path: '/savedListings',
      name: 'SavedListingPage',
      component: SavedListingPage,
      loadData: pageDataLoadingAPI.SavedListingPage.loadData,
    },
    {
      path: '/dashboard/settings',
      name: 'SettingPage',
      component: SettingPage,
      loadData: pageDataLoadingAPI.SettingPage.loadData,
    },

    {
      path: '/dashboard/bookingrequest',
      name: 'BookingRequestPage',
      component: BookingRequestPage,
      loadData: pageDataLoadingAPI.BookingRequestPage.loadData,
    },

    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            tab: 'description',
          }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      // extraProps: { allowOnlyOneListing: true },
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
    },

    {
      path: '/dashboard',
      name: 'DashboardPage',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPage,
      loadData: pageDataLoadingAPI.DashboardPage.loadData,
    },

    {
      path: '/dashboard/:slug',
      name: 'DashboardPageEarnTotal',
      auth: true,
      authPage: 'LoginPage',
      component: DashboardPage,
      loadData: pageDataLoadingAPI.DashboardPage.loadData,
    },

    {
      path: '/address-page',
      name: 'AddressPage',
      auth: true,
      authPage: 'LoginPage',
      component: AddressPage,
    },
    {
      path: '/contact-us',
      name: 'ContactUsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactUsPage,
    },
    //Before change the pagename or routename please also update the route + name in Routes.js
    {
      path: '/identity',
      name: 'IdentityPage',
      component: IdentityPage,
      auth: true,
      authPage: 'LoginPage',
    },
    {
      path: '/create-your-profile',
      name: 'CreateYourProfilePage',
      component: CreateYourProfilePage,
      auth: true,
      authPage: 'LoginPage',
    },
    {
      path: '/create-your-business-profile',
      name: 'CreateYourBusinessProfilePage',
      component: BusinessProfileCreate,
    },
    {
      path: '/business',
      name: 'BusinessProfileSelfPage',
      component: BusinessProfileSelf,
      loadData: params => pageDataLoadingAPI.ManageListingsPage.loadData(params, 'page=1&perPage=100'),
    },
    {
      path: '/b/:id',
      name: 'BusinessProfileOthersPage',
      component: BusinessProfileOthers,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/signup/business',
      name: 'SignupBusinessPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signupbusiness' },
    },
    {
      path: '/signupfrombanner',
      name: 'SignupFromBannerPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/signupfromearn',
      name: 'SignupFromEarnPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/rentals',
      name: 'RentalPage',
      auth: true,
      authPage: 'LoginPage',
      component: RentalPage,
      loadData: pageDataLoadingAPI.RentalPage.loadData,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'customer',
        }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({
          ...params,
          transactionRole: 'provider',
        }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/payment',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/stripe',
      name: 'PaymentMethodsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => (
        <NamedRedirect
          name="PaymentMethodsPage"
          params={{ tab: 'payment-methods' }}
        />
      ),
    },
    {
      path: '/account/stripe/:tab',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: PrivacyPolicyPage,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
    {
      path: '/m',
      name: 'MessageListPage',
      auth: true,
      authPage: 'LoginPage',
      component: MessageListPage,
    },
    {
      path: '/m/:id',
      name: 'MessagePage',
      auth: true,
      authPage: 'LoginPage',
      component: MessagePage,
    },
    {
      path: '/earn',
      name: 'EarnPage',
      component: EarnPage,
    },
  ];
};

export default routeConfiguration;

if (typeof window !== 'undefined') {
  window.processEnv = { ...process.env };
}
