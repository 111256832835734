import React from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { compose } from 'redux';
import { withViewport } from '../../util/contextHelpers';
import { connect } from 'react-redux';
import css from './MessageListPage.module.css';
import {
  Avatar,
  AvatarMedium,
  LayoutSingleColumn,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Logo,
  NamedLink,
  Page,
} from '../../components';
import { useConversations } from '../../firebase';
import { showUser } from '../ProfilePage/ProfilePage.duck';
import classNames from 'classnames';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { cacheUser } from './MessagePage.duck';
import { getFirebaseToken } from '../../util/api';

const MessageListPageComponent = props => {
  const { currentUser, getUser, uuidToUser, saveUser } = props;
  const [firebaseToken, setFirebaseToken] = React.useState(null);
  const [conversations] = useConversations(
    currentUser?.id?.uuid || null,
    null,
    firebaseToken
  );

  React.useEffect(() => {
    (async () => {
      conversations.forEach(async conversation => {
        if (
          !uuidToUser[
            conversation.participants.filter(
              participant => participant !== currentUser?.id?.uuid
            )?.[0]
          ]
        ) {
          const result = (
            await getUser(
              conversation.participants.filter(
                participant => participant !== currentUser?.id?.uuid
              )?.[0]
            )
          )?.data;
          if (result.data) {
            var user = JSON.parse(JSON.stringify(result.data));
            const profileImage = result?.included?.[0];
            if (profileImage) {
              user.profileImage = profileImage;
            }
            saveUser(user);
          }
        }
      });
    })();
  }, [conversations]);

  React.useEffect(() => {
    getFirebaseToken().then(response => {
      setFirebaseToken(response.firebaseToken);
    });
  }, []);

  return (
    <Page title="Messages" scrollingDisabled={true} description="Messages">
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer currentPage="AddressPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.sectionWrapper}>
          <div className={css.mainContainer}>
            <h1 className={css.title}>Messages</h1>

            <div className={css.conversationList}>
              {conversations
                .sort((a, b) => {
                  // sort by last message timestamp
                  const aTimestamp = a.messages.sort(
                    (a, b) => b.timestamp - a.timestamp
                  )[0]?.timestamp;
                  const bTimestamp = b.messages.sort(
                    (a, b) => b.timestamp - a.timestamp
                  )[0]?.timestamp;
                  return bTimestamp - aTimestamp;
                })
                .map(conversation => {
                  const user =
                    uuidToUser?.[
                      conversation.participants.filter(
                        participant => participant !== currentUser?.id?.uuid
                      )?.[0]
                    ];
                  // if message is on today, display time format like 12:00 PM
                  // else display date format like Nov 1 2020
                  const messageDateTime = new Date(
                    conversation.messages.sort(
                      (a, b) => b.timestamp - a.timestamp
                    )[0]?.timestamp
                  );
                  const today = new Date();
                  const time =
                    messageDateTime.getDate() === today.getDate() &&
                    messageDateTime.getMonth() === today.getMonth() &&
                    messageDateTime.getFullYear() === today.getFullYear()
                      ? messageDateTime.toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })
                      : messageDateTime.toLocaleDateString([], {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        });
                  const newMessages = conversation.messages.filter(
                    message =>
                      message.isNew &&
                      message.senderId !== currentUser?.id?.uuid
                  );
                  const hasNewMessages = newMessages.length > 0;

                  return user ? (
                    // <a className={css.conversation} href={`/m/${user.id.uuid}`} key={conversation.id}>
                    <NamedLink
                      className={css.conversation}
                      name="MessagePage"
                      params={{ id: user.id.uuid }}
                      key={conversation.id}
                    >
                      <AvatarMedium user={user} disableProfileLink={true} />
                      <div className={css.conversationDetails}>
                        <h3
                          className={classNames(
                            css.conversationName,
                            hasNewMessages && css.newMessage
                          )}
                        >
                          {user?.attributes?.profile?.displayName}
                        </h3>
                        <p
                          className={classNames(
                            css.conversationMessage,
                            hasNewMessages && css.newMessage
                          )}
                        >
                          {
                            conversation.messages.sort(
                              (a, b) => b.timestamp - a.timestamp
                            )[0]?.text
                          }
                        </p>
                      </div>

                      <div className={css.conversationTime}>
                        <p className={hasNewMessages && css.newMessage}>
                          {time}
                        </p>
                        {hasNewMessages && (
                          <div className={css.newMessageIndicator}>
                            <p
                              style={{
                                textAlign: 'center',
                                lineHeight: '24px',
                              }}
                            >
                              {newMessages.length}
                            </p>
                          </div>
                        )}
                      </div>
                    </NamedLink>
                  ) : null;
                })}
            </div>
          </div>
        </LayoutWrapperMain>
      </LayoutSingleColumn>
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { uuidToUser } = state.MessagePage;

  return { currentUser, uuidToUser };
};

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(showUser(userId)),
  saveUser: user => dispatch(cacheUser(user)),
});

const MessageListPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(MessageListPageComponent);

export default MessageListPage;
